import React from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useSelector } from 'react-redux';
import placeHolder from "../../../assets/placeholder.jpg"
import Breadcrumb from "../../components/Breadcrumb";
import NavBarMenu from '../../components/NavBarMenu';
import SnackBar from '../../components/SnackBar';
import CarouselCrossSell from '../../components/CarouselCrossSell';
import ProductInfos from '../../components/ProductInfos';
import BuyBox from '../../components/BuyBox';
import PictureContainer from '../../components/PictureContainer';

const ProductPage = ({ data, pageContext }) => {
  const imagesArray = [placeHolder]
  const today = new Date();
  // Add product in cart when click in 'Ajouter'
  const { snackBarLoading, snackBarOpen, snackBarMessage, snackBarError } = useSelector((state) => ({
    snackBarLoading: state.getIn(["pageList", "snackBarLoading"]),
    snackBarOpen: state.getIn(["pageList", "snackBarOpen"]),
    snackBarMessage: state.getIn(["pageList", "snackBarMessage"]),
    snackBarError: state.getIn(["pageList", "snackBarError"]),
  }));

  let currentVariant = data?.currentProduct?.childContentfulFicheProduitProductsFieldJsonNode?.products?.filter(variant => variant.offers.some(offer => JSON.parse(offer.isAvailable))).map(variant => {
    const defaultPrice = variant.offers.filter(offer => JSON.parse(offer.isAvailable))[0].priceWithVat;
    const defaultOffer = variant.offers.filter(offer => JSON.parse(offer.isAvailable))[0];
    const startDate = variant.offers[0].crossedPriceStartDate !== null && variant.offers[0].crossedPriceStartDate !== "null" ? new Date(variant.offers[0].crossedPriceStartDate) : null;
    const endDate = variant.offers[0].crossedPriceEndDate !== null && variant.offers[0].crossedPriceEndDate !== "null" ? new Date(variant.offers[0].crossedPriceEndDate) : null;
    let price = Number(defaultPrice);
    let crossedPrice = Number(variant.offers.filter(offer => JSON.parse(offer.isAvailable))[0].crossedPriceWithVat) || null;
    if (crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
      price = Number(variant.offers[0].crossedPriceWithVat);
      crossedPrice = null
    }
    return {
      price: price,
      crossedPrice: crossedPrice,
      priceDescription: defaultOffer.priceDescription,
      variantAxesValues: variant.variantAxesValues,
      images: variant.images?.map(imageId => {
        const imageInfos = ((data?.categoryImages || {}).edges || [1]).filter(({ node: image }) => image?.contentful_id === imageId)[0]?.node;
        return {
          description: imageInfos?.description || null,
          file: {
            url: imageInfos?.file?.url || null
          }
        }
      }),
      offerId: defaultOffer.id,
      isAvailable: true
    }
  })[0] || data?.currentProduct?.childContentfulFicheProduitProductsFieldJsonNode?.products?.map(variant => {
    return {
      price: variant.offers[0].priceWithVat,
      crossedPrice: null,
      variantAxesValues: variant.variantAxesValues,
      images: variant?.images?.map(imageId => {
        const imageInfos = ((data?.categoryImages || {}).edges || [1]).filter(({ node: image }) => image?.contentful_id === imageId)[0]?.node;
        return {
          description: imageInfos?.description || null,
          file: {
            url: imageInfos?.file?.url || null
          }
        }
      }),
      offerId: variant.offers[0].id,
      isAvailable: false
    }
  })[0]

  const productInfo = { // used to add to cart
    title: data.currentProduct.title,
    id: data.currentProduct.childContentfulFicheProduitProductsFieldJsonNode.products[0].id, // verif si c'est bien l'id du produit
    image: currentVariant.images[0],
    price: currentVariant.price,
    crossedPrice: currentVariant.crossedPrice,
    corporateName: data.currentProduct.seller.corporateName,
    sellerId: data.currentProduct.cataloger,
    urlProductPage: `/${pageContext.locale}/produits/${data.currentProduct.principalSveCategory.slug}/${data.currentProduct.slug}`,
    flapSelectionZawema: data.currentProduct.flapSelectionZawema,
    offerId: currentVariant.offerId,
  }

  // create list of every variant available for this current
  let variantsList = data?.variantProducts?.edges?.map(variantProduct => {
    const variantAxesValues = variantProduct?.node.childContentfulFicheProduitProductsFieldJsonNode?.products[0]?.variantAxesValues;
    let availableVariantAxes = [];
    Object.keys(variantAxesValues).map((axeValueKey) => {
      if (variantAxesValues[axeValueKey] !== "null") {
        availableVariantAxes.push({ [axeValueKey]: variantAxesValues[axeValueKey] })
      }
    });
    // generate variantName to display
    let variantName = ""
    availableVariantAxes.map((axe => {
      if (data.currentProduct.model.variantAxes) {
        const modelAxe = data.currentProduct.model.variantAxes.filter(modelVariantAxe => modelVariantAxe.code === Object.keys(axe)[0]);
        modelAxe[0].valuesCodes.map((code, key) => {
          if (Object.values(axe)[0] === code) {
            variantName += (' - ' + (modelAxe[0].values[key]))
          }
        })
      }
      variantName = variantName.slice(1);
    }))

    return {
      variantName: variantName,
      slug: "/" + pageContext.locale + "/produits/" + variantProduct.node.principalSveCategory.slug + "/" + variantProduct.node.slug
    }
  })

  // function for generate total category hierarchy
  function generateTotalCategoryHierarchy(childCategory) {
    let prefix = [];
    let allURL = [];
    let found;
    let end = false;
    let actualUrl = "/" + pageContext.locale + "/produits/";

    while (end === false) {
      found = data.allContentfulCategorySve.edges.find(
        (category) => category.node.slug === childCategory
      );
      if (!found.node.parentCategory) {
        end = true;
        allURL.push(found.node.slug + '/')
        prefix.push({
          label: found.node.categoryEncartTitle ? found.node.categoryEncartTitle : found.node.categoryName,
        });
        // Permits to longer the url as long as there are categories
        for (var i = prefix.length - 1; i >= 0; i--) {
          prefix[i].url = actualUrl + allURL[i];
          actualUrl = prefix[i].url
        }
      }
      else {
        allURL.push(found.node.slug + '/')
        prefix.push({
          label: found.node.categoryEncartTitle ? found.node.categoryEncartTitle : found.node.categoryName,
        });
        childCategory = found.node.parentCategory.slug;
      }
    }
    const flattenPrefix = prefix.flat(5).reverse();
    return flattenPrefix;
  }

  // Product for section 'Our clients also liked'
  let linkedProductList = data.currentProduct?.linkedProducts?.filter(productContent => {
    if (productContent.childContentfulFicheProduitProductsFieldJsonNode == null) return false;
    const variants = productContent.childContentfulFicheProduitProductsFieldJsonNode.products || [];

    let productContentHasAtLeastOneAvailableOffer = false;
    variants.map((variant) => {
      (variant.offers || []).forEach((offer) => {
        if (offer.isAvailable) {
          productContentHasAtLeastOneAvailableOffer = true;
        }
      });
    });

    return productContentHasAtLeastOneAvailableOffer;
  }).map(productContent => {
    const defaultVariant = productContent.childContentfulFicheProduitProductsFieldJsonNode.products[0];
    const defaultImage = defaultVariant.images?.map(imageId => {
      const imageInfos = ((data?.categoryImages || {}).edges || [1]).filter(({ node: image }) => image?.contentful_id === imageId)[0]?.node;
      return {
        description: imageInfos?.description || null,
        file: {
          url: imageInfos?.file?.url || null
        }
      }
    });
    const defaultOffer = defaultVariant.offers[0];
    const defaultSeller = productContent.seller.corporateName || {};
    const defaultSellerId = productContent.cataloger || {};
    return {
      title: productContent.title,
      id: defaultVariant.id,
      image: defaultImage[0],
      price: defaultOffer.priceWithVat,
      crossedPrice: defaultOffer.crossedPriceWithVat,
      corporateName: defaultSeller,
      sellerId: defaultSellerId,
      urlProductPage: `/${pageContext.locale}/produits/${productContent.principalSveCategory.slug}/${productContent.slug}`,
      flapSelectionZawema: productContent.flapSelectionZawema,
      offerId: defaultOffer.id
    }
  });

  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}/${pageContext.locale}/produits/${data.currentProduct.principalSveCategory.slug}/${pageContext.slug}`,
  },
  {
    name: 'title',
    content: `${productInfo.title} sur Zawema`,
  },
  {
    name: 'og:title',
    content: `${productInfo.title} sur Zawema`,
  },
  {
    name: 'description',
    content: data?.currentProduct?.shortDescription?.shortDescription || "",
  },
  {
    name: 'og:description',
    content: data?.currentProduct?.shortDescription?.shortDescription || "",
  }];

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={pageContext.slug}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      <Helmet>
        <html lang="fr" />
        <title>{productInfo.title}</title>
        {/* Meta and OpenGraph */}
        {initMetas && initMetas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>
      <NavBarMenu
        firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
          return (
            sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
          )
        })}
        secondLevelCategories={data.menuSecondLevel.edges}
        thirdLevelCategories={data.menuThirdLevel.edges}
        lang={pageContext.locale}
      />
      <Breadcrumb
        lastItemLabel={data?.currentProduct?.title}
        parentItems={generateTotalCategoryHierarchy(pageContext.categoryName)}
      />

      <div className="max-w-full mx-3 mt-4 lg:mx-28">
        <div className="flex flex-col md:flex-row">
          <PictureContainer images={currentVariant.images[0]?.file?.url ? currentVariant.images : imagesArray} />

          <div className="text-left flex-1">
            <BuyBox
              currentProduct={data.currentProduct}
              linkProductDetails={`./#product-details`}
              productInfo={productInfo}
              currentVariant={currentVariant}
              lang={pageContext.locale}
              variantsList={variantsList}
            />
          </div>
        </div>
        <div className="max-w-screen-2xl mx-auto">
          <ProductInfos currentProduct={data.currentProduct} attributesList={data.attributesList.edges} otherAttributes={data.otherAttribute.edges} />
        </div>
      </div>

      {/* Carousel section */}
      {linkedProductList && (
        <div className='w-full flex flex-col items-center md:bg-orange-light py-6 md:px-20'>
          <h2 className='text-center font-bold text-sm md:text-semi mb-6'>Nos clients ont aussi aimé :</h2>
          <div className='w-3/4 hidden md:flex justify-center items-center'>
            <CarouselCrossSell autoPlayCarousel products={linkedProductList} numberSlide={3} />
          </div>
          <div className='block md:hidden w-full justify-center items-center'>
            <CarouselCrossSell autoPlayCarousel products={linkedProductList} numberSlide={1} />
          </div>
        </div>
      )}


      {!snackBarLoading && <SnackBar open={snackBarOpen} error={snackBarError} message={snackBarMessage} />}
    </Layout>
  );
};

export default ProductPage;

export const query = graphql`
  query($slug: String!, $locale: String!, $sellerId: String!, $sellerVariantGroup: String!, $productId: String!) {
    currentProduct: contentfulFicheProduit(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      Description {
        raw
      }
      attributes {
        internal {
          content
        }
      }
      model {
        caracteristics {
          code
        }
      }
      childContentfulFicheProduitProductsFieldJsonNode {
        id
            products {
            images
            id
            offers {
              crossedPriceEndDate
              crossedPriceStartDate
              crossedPriceWithVat
              id
              isAvailable
              priceWithVat
              priceDescription
              leadtimeToShip
              sellerSku
              sellerId
            }
            sellerVariantGroup
            variantAxesValues {
              size
              color
              colorAcc
              HairColor
              capacityL
              teintColor
              MaterialAcc
              quantityWeight
            }
          }
      }
      flapSelectionZawema
      title
      slug
      cataloger
      model {
        code
        id
        modelName
        variantAxes {
          values
          name
          code
          valuesCodes
        }
      }
      shortDescription {
        shortDescription
      }
      principalSveCategory {
        categoryName
        slug
      }
      seller {
        corporateName
        slug
        id
      }
      linkedProducts {
        title
        seller {
          corporateName
        }
        slug
        flapSelectionZawema
        principalSveCategory {
          slug
        }
        childContentfulFicheProduitProductsFieldJsonNode {
          id
            products {
              images
              id
              offers {
                crossedPriceEndDate
                crossedPriceStartDate
                crossedPriceWithVat
                id
                isAvailable
                priceWithVat
                priceDescription
                leadtimeToShip
                sellerSku
                sellerId
              }
            }
        }
      }
    }
    variantProducts: allContentfulFicheProduit(filter: {seller: {sellerId: {eq: $sellerId}}, node_locale: {eq: $locale}, childContentfulFicheProduitProductsFieldJsonNode: {products: {elemMatch: {sellerVariantGroup: {eq: $sellerVariantGroup}, id: {ne: $productId}}}}}) {
      edges {
        node {
          id
          seller {
            id
          }
          principalSveCategory {
            slug
          }
          childContentfulFicheProduitProductsFieldJsonNode {
            products {
              id
              offers {
                id
                isAvailable
              }
              sellerVariantGroup
              variantAxesValues {
                size
                color
                colorAcc
                HairColor
                capacityL
                teintColor
                MaterialAcc
                quantityWeight
              }
            }
          }
          slug
        }
      }
    }

    menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
      edges {
        node {
          slug
          categoryName
        }
      }
    }
    menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
            }
          } 
        }
      }
    }
    attributesList : allContentfulAttributProduit(filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          values
          valuesCodes
          name
          code
        }
      }
    }
    otherAttribute: allContentfulAttribute(filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          name
          code
          values
          valuesCodes
        }
      }
    }
    allContentfulCategorySve {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    categoryImages: allContentfulAsset(filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          title
          file {
            url
          }
          description
          contentful_id
        }
      }
    }
  }
`;
