import React from "react";
import { withTranslation } from "react-i18next";
import { ChevronRightIcon } from "@heroicons/react/solid";

// add useBreadcrumb hook from gatsby-plugin-breadcrumb with it in pages or templates
const Breadcrumb = ({ t, lastItemLabel, parentItems }) => {

  return (
    <nav className="flex md:pl-28" aria-label="Breadcrumb">
      <ol className="flex flex-wrap items-center space-x-4">
        <li>
          <div>
            <a href="/" rel="noopener noreferrer" className="ml-4 text-xxm text-grey-light w-full">
              {t("Home")}
            </a>
          </div>
        </li>
        {parentItems.map((page) => (
          <li key={page.label}>
            <div className="flex items-center">
              <ChevronRightIcon
                className="flex-shrink-0 h-4 w-4 text-grey-light"
                aria-hidden="true"
              />
              <a
                href={page.url}
                rel="noopener noreferrer"
                className="ml-4 text-xxm text-grey-light"
              >
                {page.label}
              </a>
            </div>
          </li>
        ))}
        <li className="flex items-center">
          <ChevronRightIcon
            className="flex-shrink-0 h-4 w-4 text-grey-light"
            aria-hidden="true"
          />
          <span className="ml-4 text-xxm text-grey-light">
            {lastItemLabel}
          </span>
        </li>
      </ol>
    </nav>
  );
};

export default withTranslation()(Breadcrumb);
