import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getStyles from './styles';
import { useStyles } from "react-styles-hook";
import { useDispatch } from 'react-redux'
import { launchedSnackBar } from "../../templates/PageList/reducer";
import { useCart } from "react-use-cart";
import { Link } from "gatsby";
import { navigate } from 'gatsby-link';

const BuyBox = ({ currentProduct, linkProductDetails, productInfo, currentVariant, variantsList, lang }) => {
  let defaultVariantName = "";

  // get every available variant axe of current product
  const variantAxesValues = currentProduct?.childContentfulFicheProduitProductsFieldJsonNode?.products[0]?.variantAxesValues;
  let availableVariantAxes = [];
  Object.keys(variantAxesValues)?.map((axeValueKey) => {
    if (variantAxesValues[axeValueKey] !== "null") {
      availableVariantAxes.push({ [axeValueKey]: variantAxesValues[axeValueKey] })
    }
  });

  // generate default variant's name of current product
  availableVariantAxes?.map((axe => {
    if (currentProduct.model.variantAxes) {
      const modelAxe = currentProduct.model.variantAxes.filter(modelVariantAxe => modelVariantAxe.code === Object.keys(axe)[0]);
      if (modelAxe.length !== 0) {
        modelAxe[0]?.valuesCodes?.map((code, key) => {
          if (Object.values(axe)[0] === code) {
            defaultVariantName += (' - ' + (modelAxe[0].values[key]))
          }
        });
        defaultVariantName = defaultVariantName.slice(1);
      }
    }
  }))


  const [quantity, setQuantity] = useState(1);
  const classes = useStyles(getStyles());
  const selectQuantity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const dispatch = useDispatch();
  const { addItem } = useCart();

  const handleClick = (product) => {
    dispatch(launchedSnackBar())
    addItem(product, quantity)
  }

  return (
    <>
      {currentProduct.flapSelectionZawema && (
        <h2 className="font-semibold mb-4 text-xs md:text-s">
          Sélection <span className="text-orange-dark"> Zawema</span>
        </h2>
      )}
      <Link to={`/${lang}/seller/${currentProduct.seller.slug}`} className="mb-4 text-sm">
        {currentProduct
          .childContentfulFicheProduitProductsFieldJsonNode
          ?.products[0]?.offers[0]?.corporateName ||
          currentProduct
            ?.seller?.corporateName}
      </Link>
      <h2 className="font-semibold text-sm md:text-base mb-4">
        {currentProduct.title}
      </h2>
      <p className="mb-7 text-xs md:text-sm text-left">
        {
          currentProduct.shortDescription
            ?.shortDescription
        }
      </p>
      <Link
        to={linkProductDetails}
        className="text-grey-lightDark mb-12 underline text-s flex items-center"
      >
        + de détails
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="#707070">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </span>
      </Link>

      <div className="flex mb-6 mt-6">
        <p className="text-large text-orange-darkest">
          {
            ((currentVariant.price) * 100 / 100).toFixed(2)
          } €
        </p>
        {(currentVariant.crossedPrice !== "null" && currentVariant.crossedPrice !== null && currentVariant.crossedPrice !== 0 && currentVariant.crossedPrice !== "0") && (
          <p className="line-through text-semi text-orange-crossed ml-6">
            {
              ((currentVariant.crossedPrice) * 100 / 100).toFixed(2)
            } €
          </p>
        )}
      </div>
      {(currentVariant.priceDescription && currentVariant.priceDescription !== "null") && (
        <div className="flex flex-row justify-left pb-1 text-xs text-grey-dark font-normal italic">
          <span>{currentVariant.priceDescription}</span>
        </div>
      )}
      <div className="flex flex-row">
        <div>
          <div className="text-xs md:text-sm">
            <label for="Quantité">Quantité</label>
          </div>
          <select
            className="w-24 text-center"
            name="valeur"
            id="valeur-quantité"
            onChange={(e) => {
              setQuantity(Number(e.target.value));
            }}
          >
            {selectQuantity.map((quantity) => (
              <option value={quantity}>{quantity}</option>
            ))}
          </select>
        </div>
        {/* display select list of product's variant */}
        {(variantsList && variantsList.length !== 0) && (
          <div className="ml-4 w-full">
            <div className="text-xs md:text-sm">
              <label for="version">Version</label>
            </div>
            <select defaultValue="" className="w-full lg:w-3/4 xl:w-2/5 truncate" name="version" id="version" onChange={(e) => navigate(e.target.value)}>
              <option value="" disabled label={defaultVariantName} />
              {variantsList?.map((variant) =>
                <option value={variant.slug}>{variant.variantName}</option>
              )}
            </select>
          </div>
        )
        }
      </div>
      <div className="mt-6 mb-12">
        <button
          className={
            "w-full md:w-80 h-12 text-white bg-black"
          }
          onClick={() => handleClick(productInfo)}
          disabled={!currentVariant.isAvailable}
        >
          {currentVariant.isAvailable ? "Ajouter" : "Produit indisponible"}
        </button>
      </div>
      <p className="text-sm">
        Vendu par{" "}
        <Link to={`/${lang}/seller/${currentProduct.seller.slug}`} className="text-s md:text-sm underline">
          {currentProduct
            ?.childContentfulFicheProduitProductsFieldJsonNode
            ?.products[0]?.offers[0]?.corporateName ||
            currentProduct
              ?.seller?.corporateName}
        </Link>
      </p>
    </>
  )
};

export default BuyBox

BuyBox.propTypes = {
  currentProduct: PropTypes.object.isRequired,
  linkProductDetails: PropTypes.array.isRequired,
  productInfo: PropTypes.object.isRequired,
  currentVariant: PropTypes.object.isRequired
};
