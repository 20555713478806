import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import getStyles from './styles';
import { useStyles } from "react-styles-hook";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PropTypes from 'prop-types';
import ProductListCard from '../ProductListCard';
import clsx from 'clsx';

const CarouselCrossSell = ({ products, images, autoPlayCarousel, numberSlide }) => {

  const classes = useStyles(getStyles());
  const [currentSlide, setCurrentSlide] = useState(0)

  const next = () => {
    setCurrentSlide(currentSlide + 1)
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1)
  };

  const updateCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index)
    }
  };

  return (
    <>
      {products?.length > 1 &&
        <>
          <div onClick={prev} style={classes.arrowButtonLeft} className="pt-12 md:pt-0">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </div>
          <div onClick={next} style={classes.arrowButtonRight} className="pt-12 md:pt-0">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
          <Carousel
            autoPlay={autoPlayCarousel ? true : false}
            interval={3000}
            infiniteLoop
            centerMode
            centerSlidePercentage={100 / numberSlide}
            onChange={updateCurrentSlide}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            selectedItem={currentSlide}
            renderItem={(item) => (
              <div className={clsx(products ? "w-1/2" : images && "w-full", "flex justify-center mx-auto")}>
                {item}
              </div>
            )}
          >
            {
              products
                ? products.map((product, key) => {
                  return (
                    <div key={key} className="w-5/6 pb-4">
                      <ProductListCard product={product} />
                    </div>
                  )
                }) : null
            }
          </Carousel>
        </>
      }
      {(products && products.length === 1) &&
        <div className="w-5/6 flex justify-center mx-auto">
          <div className="pb-4">
            <ProductListCard product={products[0]} />
          </div>
        </div>
      }
      {/* Mobile carrousel product img */}
      {(!products && images) && (
        <>
          <div onClick={prev} style={classes.arrowButtonLeft}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </div>
          <div onClick={next} style={classes.arrowButtonRight}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </div>
          <Carousel
            autoPlay={autoPlayCarousel ? true : false}
            interval={3000}
            infiniteLoop
            centerMode
            centerSlidePercentage={100 / numberSlide}
            onChange={updateCurrentSlide}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            selectedItem={currentSlide}
            renderItem={(item) => (
              <div className={clsx(products ? "w-5/6" : images && "w-full", "flex justify-center mx-auto")}>
                {item}
              </div>
            )}
          >
            {
              images
              && images.map((image, key) => {
                return (
                  <img
                    key={key}
                    className="object-scale-down h-36 w-auto"
                    src={image.file.url}
                    alt={image.description}
                  />
                )
              })
            }
          </Carousel>
        </>
      )}
    </>
  );
}
export default CarouselCrossSell

CarouselCrossSell.propTypes = {
  products: PropTypes.array.isRequired,
  numberSlide: PropTypes.number.isRequired
};

