import React from "react";
import PropTypes from "prop-types";
import getStyles from "./styles";
import { useStyles } from "react-styles-hook";
import { capitalizeFirst } from '../../utils/format';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS } from "@contentful/rich-text-types";

const ProductInfos = ({ currentProduct, attributesList, otherAttributes }) => {

  const classes = useStyles(getStyles());

  const attributes = currentProduct.attributes ? JSON.parse(currentProduct.attributes?.internal?.content) : {};

  const caracteristics = currentProduct?.model?.caracteristics?.map(caracteristic => {
    const attribute = attributesList.filter(x => x.node.code === caracteristic.code)[0].node;
    if (attribute.code !== "hairType1" && attribute.code !== "Hairtype2Awalker") { // type of other caracteristic : string (one value per carac)
      let caracteristicValue
      if (attribute.valuesCodes) {
        const index = attribute.valuesCodes.indexOf(attributes[caracteristic.code])
        caracteristicValue = attribute.values[index]
      } else {
        caracteristicValue = attributes[caracteristic.code]
      }
      return caracteristicValue ? {
        caracteristic: attribute.name,
        value: caracteristicValue
      } :
        null
    } else { // hairType1 && HairType2Awalker case (potentiel multivalue => type of array)
      let concatValue = "";
      attribute.valuesCodes.map((valueCode, keyOfValueCode) => { // on map sur les values codes de l'attr contentful
        if (attributes[attribute.code]) {
          attributes[attribute.code].map((attrValueOfKey) => { // on map sur les values code de l'attr produit
            if (valueCode === attrValueOfKey) {
              concatValue += (" - " + capitalizeFirst(attribute.values[keyOfValueCode]));
            } return null;
          })
        } else return null;
      });
      concatValue = concatValue.slice(3)
      return (concatValue !== "") ? {
        caracteristic: attribute.name,
        value: concatValue
      } :
        null
    }

  }).filter(x => x !== null);

  let otherAttribute = []; // otherAttribute === facet produit
  Object.keys(attributes).map((key) => { // on map sur les attr du produit (brand, hair adapation)
    otherAttributes.map((attr) => { // on map sur les attributs config sur contentful
      if (attr.node.code === key) { // si l'attr contentful = attr du produit
        let concatValue = "";
        attr.node.valuesCodes.map((valueCode, keyOfValueCode) => { // on map sur les values codes de l'attr contentful
          if (typeof (attributes[key]) === "object") {
            attributes[key].map((attrOfKey) => { // on map sur les values code de l'attr produit
              if (attrOfKey === valueCode) { // si la value code de l'attr contentful = la value code code de l'attr produit
                concatValue += (" - " + capitalizeFirst(attr.node.values[keyOfValueCode])); // on ajoute aux string des values la valeur de attr.node.value
              } return null;
            });
            return null;
          } else return null;

        })
        concatValue = concatValue.slice(3)
        return (concatValue ?
          otherAttribute.push({
            caracteristic: attr.node.name,
            value: concatValue
          }) : null);
      } else return null;
    })
  }).filter(x => x !== null);

  function replaceWithBr(text) {
    return text.toString().replace(/\n/g, "<br />")
  }

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <div className="pb-2"><p dangerouslySetInnerHTML={{ __html: replaceWithBr(children) }} /></div>,
    },
  }

  return (
    <>
      {/* Product details section */}
      <div id='product-details' className="md:pl-8 mb-10">
        <h3 className="font-semibold text-sm md:text-semi mt-4 md:mt-10">
          Description <span className="text-orange-dark">détaillée</span>
        </h3>
        <div className="text-sm pt-6">
          {currentProduct.Description ?
            <div>
              {renderRichText(currentProduct.Description, options)}
            </div>
            : null
          }
        </div>
      </div>

      {/* Product features section */}
      {((caracteristics && caracteristics !== null && caracteristics.length !== 0) || otherAttribute.length !== 0) && (
        <div className="md:pl-8 mb-10">
          <h4 className="font-semibold text-sm md:text-semi">
            Caractéristiques
            <span className="text-orange-dark"> du produit</span>
          </h4>
          {(caracteristics && caracteristics !== null) && (
            <table className="table-fixed w-full sm:w-5/6 lg:w-4/5 xl:w-2/3 mt-4">
              <tbody style={classes.tableBorder}>
                {caracteristics.map((rowData) => (
                  <tr>
                    <td className="bg-orange-light w-1/3 md:w-1/4 text-xs px-2 md:px-4 py-2" style={classes.tableBorder}>
                      {capitalizeFirst(rowData.caracteristic)}
                    </td>
                    <td className="w-2/3 md:w-3/4 text-xs px-2 md:px-4 py-2" style={classes.tableBorder}>
                      {rowData.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
          {(otherAttribute.length !== 0) && (
            <table className="table-fixed w-full sm:w-5/6 lg:w-4/5 xl:w-2/3 mt-4">
              <tbody style={classes.tableBorder}>
                {otherAttribute.map((rowData) => (
                  <tr>
                    <td className="bg-orange-light w-1/3 md:w-1/4 text-xs px-2 md:px-4 py-2" style={classes.tableBorder}>
                      {capitalizeFirst(rowData.caracteristic)}
                    </td>
                    <td className="w-2/3 md:w-3/4 text-xs px-2 md:px-4 py-2" style={classes.tableBorder}>
                      {rowData.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </>
  );
};


export default ProductInfos;

ProductInfos.propTypes = {
  currentProduct: PropTypes.object.isRequired,
  attributesList: PropTypes.array.isRequired
};