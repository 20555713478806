const getStyles = (theme) =>({
root: {
  height: '45vh',
  width: '100%',
  paddingBottom: '20%'
},
view: {
  height: '40vh'
},
arrowButtonLeft: {
  position: 'absolute',
  zIndex: 10,
  cursor: 'pointer',
  left: '5%'
},
arrowButtonRight: {
  position: 'absolute',
  zIndex: 10,
  cursor: 'pointer',
  right: '5%'
},
arrowIcon: {
  color: '#fff',
  fontSize: 60
},
mainContainer: {
  width: "100%",
  height: "100%",
  position: 'absolute',
  top: 0
},
titleContainer: {
  justify: "center",
  width: "100%",
  height: "40%",
  backgroundColor: 'rgb(255, 255, 255, 0.8)',
  borderRadius: 10,
  padding: 0
},
title: {
  marginBottom: 20
},
link: {
  textDecoration: 'none',
  color: '#000',
  width: '70%'
}
});

export default getStyles;