import React from 'react';
import getStyles from './styles';
import { useStyles } from "react-styles-hook";
// import clsx from 'clsx';

export const SnackBar = ({ message, open, error }) => {
  const classes = useStyles(getStyles());

  // const renderIcon = () => {
  //   switch (type) {
  //     case 'Success':
  //       return <CheckCircleOutlineIcon fontSize="medium" className={classes.icon} />;
  //     case 'Error':
  //       return <ReportProblemOutlinedIcon fontSize="medium" className={classes.icon} />;
  //     case 'Infos':
  //       return <InfoOutlinedIcon fontSize="medium" className={classes.icon} />;
  //     default:
  //       return null;
  //   }
  // };

  return (
    <>
      {
        open &&
        <div
          className="flex justify-center items-center bg-white z-50 fixed right-6 md:right-0 top-6 md:top-36 w-5/6 md:w-1/3 h-12 md:h-16"
          style={classes.container}
        >
          {
            error
            ? <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-10 mr-4 md:mr-2" fill="none" viewBox="0 0 24 24" stroke="#ED2828">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
            </svg>
            : <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-10 " fill="none" viewBox="0 0 24 24" stroke="#63A570">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M5 13l4 4L19 7" />
            </svg>
          }
          <p className="text-xs md:text-sm">{message}</p>
        </div>
      }
    </>
  );
};

export default SnackBar;
