import React, { useState } from 'react';
import PropTypes from 'prop-types';
import getStyles from './styles';
import { useStyles } from "react-styles-hook";
import CarouselCrossSell from '../CarouselCrossSell';
import clsx from 'clsx';

const PictureContainer = ({ images }) => {
  const classes = useStyles(getStyles());
  const [imageSrc, setImageSrc] = useState(images[0].file.url)
  const [imageDesc, setImageDesc] = useState(images[0].description)
  return (
    <>
      {/* Image in desktop */}
      <div className="w-1/2 hidden md:flex md:-80 lg:h-96">
        <div className="w-1/5 grid grid-rows-4 gap-2">
          {
            images.map((image, key) => {
              return (
                <div className="h-20 lg:h-24">
                  <img
                    key={key}
                    src={image.file.url || image}
                    alt={image.description || "creme !"}
                    onClick={(e) => setImageSrc(e.target.attributes.src.nodeValue) && setImageDesc(e.target.attributes.alt.nodeValue)}
                    className={clsx("w-full h-full object-contain cursor-pointer", ((imageSrc === image.url) || (image === imageSrc)) && "border border-orange-dark")}

                  />
                </div>
              )
            })
          }
        </div>
        <div className="w-4/5 sm:h-full">
          <div class="px-6 relative w-full h-64 bg-white overflow-hidden sm:h-full">
            <img src={imageSrc} alt={imageDesc || images[0].description} className="w-full h-full object-contain" />
          </div>
        </div>
      </div>

      {/* Image in mobile */}
      <div className="w-full flex md:hidden justify-center items-center mx-auto my-6">
        <CarouselCrossSell images={images} numberSlide={1} />
      </div>
    </>
  )
};

export default PictureContainer

PictureContainer.propTypes = {
  images: PropTypes.array.isRequired,
};